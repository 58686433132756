<template>
  <div class="flex items-center gap-x-1 flex-nowrap w-10 h-10">
    <button
      class="relative w-full h-full"
      :title="'User menu'"
      :aria-label="'User menu'"
      @click="showMenu"
    >
      <font-awesome-icon
        :icon="icon"
        class="w-auto h-auto min-w-full min-h-full rounded-full opacity-80 hover:opacity-100"
      ></font-awesome-icon>
    </button>
  </div>
</template>

<script lang="ts">
import {computed} from 'vue';
import SidebarMenu from '../../../generic/sidebar-menu/SidebarMenu.vue';
import {HierarchicalMenuItem} from '../../../generic/hierarchical-menu/hierarchical-menu-types';
import {useSidebar} from '../../../vue-composition/sidebar/sidebar';
import {useDeviceType} from '../../../vue-composition/device-type/device-type';
import {faUserCircle} from '@fortawesome/pro-solid-svg-icons/faUserCircle';
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';

export default {
  components: {
    FontAwesomeIcon
  },
  setup() {
    const deviceType = useDeviceType();
    const loginUrl = computed(() => {
      return `/login?redirect=${window.location.href}`;
    });

    const menu = computed(() => {
      const root: HierarchicalMenuItem = {
        title: '',
        parent: undefined,
        children: [],
        divider: 'none',
        display: 'loggedIn'
      };
      root.children.push({
        title: 'My Account',
        url: '/account',
        parent: root,
        children: [],
        divider: 'none',
        display: 'loggedIn'
      });
      root.children.push({
        title: 'Edit Profile',
        url: '/profile',
        parent: root,
        children: [],
        divider: 'none',
        display: 'loggedIn'
      });
      root.children.push({
        title: 'Bookmarks',
        url: '/bookmarks',
        parent: root,
        children: [],
        divider: 'none',
        display: 'loggedIn'
      });
      root.children.push({
        title: 'History',
        url: '/history',
        parent: root,
        children: [],
        divider: 'below',
        display: 'loggedIn'
      });
      root.children.push({
        title: 'Log out',
        url: '/logout',
        parent: root,
        children: [],
        divider: 'none',
        display: 'loggedIn'
      });
      return root;
    });

    const sidebar = useSidebar();
    const showMenu = () => {
      if (menu.value !== undefined) {
        sidebar.show(
          SidebarMenu,
          {
            guest: '',
            menu: menu.value,
            onClose: () => {
              sidebar.hide();
            },
            orientation: 'right'
          },
          'right'
        );
      }
    };

    const icon = computed(() => faUserCircle);

    return {
      deviceType,
      showMenu,
      loginUrl,
      icon
    };
  }
};
</script>
