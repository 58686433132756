<template>
  <div v-if="enabled" :class="'absolute top-0 left-0 w-full h-full z-50'">
    <div
      id="modal-overlay"
      :class="`absolute top-0 left-0 w-full h-full ${blur-sm}`"
      aria-hidden="true"
      @click.prevent="onClick"
    ></div>
    <div id="modal-overlay-attach" ref="modalOverlayAttach" :class="'absolute bg-dark'"></div>
  </div>
</template>

<script lang="ts">
import {defineComponent, computed, ref, watch} from 'vue';
import {useStore} from '../../../../store/store';

export default defineComponent({
  components: {},
  setup() {
    const modalOverlayAttach = ref(null as InstanceType<typeof HTMLDivElement> | null);
    const store = useStore();
    const enabled = computed(() => {
      const status = store.getters['modalOverlay/status'];
      return status;
    });
    const onClick = () => {
      store.dispatch('modalOverlay/deactivate');
    };
    const app = computed(() => {
      const app = store.getters['modalOverlay/app'];
      return app;
    });
    const blur = computed(() => {
      return store.getters['modalOverlay/blur'] ? 'bg-black/80 backdrop-blur-sm' : '';
    });

    watch(modalOverlayAttach, status => {
      if (status !== null && app.value !== undefined) {
        app.value.mount('#modal-overlay-attach');
      }
    });

    return {
      enabled,
      onClick,
      modalOverlayAttach,
      blur
    };
  }
});
</script>
